@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
@import "~antd/dist/antd.compact.less";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

//@import '~antd/dist/antd.less';

#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(132, 132, 132, 0.2);
}

#header-menus {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.header-menu {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color: #444343;
    display: none;
}

.text-center {
    text-align: center;
}

.border {
    border: 1px solid #f0f0f0;
}

/* ------- Home ------- */
.home-page-landing {
    width: 100%;
    padding: 15vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-category-section {
    width: 100%;
    padding: 1vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(224 227 231);
}

.home-category-section .category-list-container .collection-category-btn {
    // height: 100px;
    // font-size: 15px;
    // width: 150px;
    // text-wrap: wrap;
    // line-height: 1.2;
    // text-align: center;
    // text-transform: uppercase;
    font-weight: bold;
    font-family: "Inter";
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    // background-color: #4a6a8e;
    // background: linear-gradient(45deg, #4339b1e0 30%, #30b0a5 100%);
}

.home-category-section > h2 {
    font-size: 30px;
    font-family: "Inter";
    font-weight: 500;
}

.home-category-section .category-list-container .collection-category-btn svg {
    display: none;
}

#home-page-heading {
    width: 300px;
}

#home-page-heading > h1 {
    font-family: "Inter";
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin: 25px 0 15px 0;
}

#home-page-heading > h1 .multi-sensory {
    font-weight: bold;
    text-decoration: underline;
    background: linear-gradient(to right, #30b0a5, #433bb1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    cursor: pointer;
}

#home-page-heading > h1 .multi-sensory::after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 7px;
    height: 2px;
    background: linear-gradient(to right, #30b0a5, #433bb1);
}

.home-page-intro-video {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 10px;
}

.home-page-intro-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.intro-modal-home-page .ant-descriptions-item span {
    font-size: 18px;
}

// #home-page-heading > h1 .multi-sensory:hover {
//     background: linear-gradient(to right, #30b0a5, #c10000);
//     background-clip: text;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

// #home-page-heading > h1 .multi-sensory:hover::after {
//     background: linear-gradient(to right, #30b0a5, #c10000);
// }

#home-page-heading > p {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
}

#home-page-landing-btns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

#home-page-explore-btn,
.explore-btn {
    display: inline-block;
    background: linear-gradient(90deg, #30b0a5 21.17%, #4339b1 100%);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
    color: white;
}

.explore-btn:hover {
    color: rgb(219, 219, 219);
}

#home-page-request-demo-btn,
#be-an-author-btn {
    background-color: rgb(74, 106, 142);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
    color: rgb(255, 255, 255);
}

/* ------- Authors ------- */
.author-page-landing {
    width: 100%;
    padding: 15vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#author-page-heading {
    width: 300px;
}

#author-page-heading > h1 {
    font-family: "Inter";
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin: 25px 0 15px 0;
}

#author-page-heading > p {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
}

#author-page-landing-btns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

#author-page-explore-btn {
    display: inline-block;
    background: linear-gradient(90deg, #30b0a5 21.17%, #4339b1 100%);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
    color: white;
}

#author-page-request-demo-btn {
    background-color: rgb(74, 106, 142);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
    color: rgb(255, 255, 255);
}

.author-page-titles {
    margin: 0 auto;
    text-align: center;
    padding: 100px 20px;
    background-color: #faf9f9;
}

.author-page-titles > h2 {
    font-size: 40px;
    font-family: "Inter";
    font-weight: 500;
}

.author-page-titles > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    gap: 100px;
    margin: 0 auto;
}

.author-page-intro-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
}

.author-page-intro-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.author-page-titles.contain {
    padding: 100px calc((100% - 1200px) / 2);
}

.author-page-titles img {
    width: 100%;
    margin: 36px 0 0;
}

#how-it-works.author-page-titles {
    margin: 0 auto;
    text-align: center;
    background-color: #f0f2f5;
}

#why-cubits-works {
    margin: 0 auto;
    text-align: center;
    padding: 100px 20px;
    background-color: #faf9f9;
}

#why-cubits-works > h2 {
    font-size: 40px;
    font-family: "Inter";
    font-weight: 500;
}

#why-cubits-works > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    gap: 100px;
    margin: 0 auto;
}

#why-cubits-works-watch {
    margin-top: 20px;
    background-color: #353535;
    padding: 20px 30px;
}

#why-cubits-works-watch > p {
    color: white;
}

#why-cubits-works-interact {
    margin-top: 10px;
    background-color: #7b9ea8;
    padding: 20px 30px;
}

#why-cubits-works-interact > p {
    color: white;
}

#why-cubits-works-analyze {
    margin-top: 10px;
    background-color: #31467c;
    padding: 20px 30px;
}

#why-cubits-works-analyze > p {
    color: white;
}

#why-cubits-works.contain {
    padding: 100px calc((100% - 1200px) / 2);
}

#how-you-get-paid {
    margin: 0 auto;
    text-align: center;
    padding: 100px 20px;
    background-color: initial;
}

#how-you-get-paid > h2 {
    font-size: 40px;
    font-family: "Inter";
    font-weight: 500;
}

#how-you-get-paid > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    gap: 100px;
    margin: 0 auto;
}

#how-you-get-paid-watch {
    margin-top: 20px;
    background-color: #353535;
    padding: 20px 30px;
}

#how-you-get-paid-watch > p {
    color: white;
}

#how-you-get-paid-interact {
    margin-top: 10px;
    background-color: #7b9ea8;
    padding: 20px 30px;
}

#how-you-get-paid-interact > p {
    color: white;
}

#how-you-get-paid-analyze {
    margin-top: 10px;
    background-color: #31467c;
    padding: 20px 30px;
}

#how-you-get-paid-analyze > p {
    color: white;
}

#how-you-get-paid-analyze p {
    color: white;
    font-size: 18px;
}

#how-you-get-paid.contain {
    padding: 100px calc((100% - 1200px) / 2);
}

.why-cubits-works-para {
    font-size: 18px;
}

.why-cubits-works-para.heading {
    font-size: 24px;
    margin-top: 20px;
}

#home-collections-section-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

#home-collections-section-view-all {
    color: white;
    text-decoration: underline;
    margin-right: 10px;
    font-size: 20px;
}

#prmoted-collections-section-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0;
    color: #fff;
}

#promoted-collections-section-view-all {
    text-decoration: underline;
    margin-right: 10px;
    width: 100px;
    text-align: right;
    color: #fff;
}

#home-collections-section-title {
    font-family: "Inter";
    font-size: 25px;
    font-weight: 700;
    line-height: 22px;
    color: white;
}

.home-collection-one {
    background-color: #020b18;
    padding: 20px 30px;
    transition: all 0.3s ease-in;
}

.home-collection-two {
    background-color: #2e4d7d;
    padding: 20px 30px;
    transition: all 0.3s ease-in;
}

.home-collection-three {
    background-color: #ac2430;
    padding: 20px 30px;
    transition: all 0.3s ease-in;
}

.home-collection {
    padding: 10px 10px;
    background-color: white;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.home-collection-image-bio {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.home-collection-bio {
    background-color: #f5f5f5;
    padding: 15px 25px;
    border-radius: 10px;
}

.home-collection-image {
    width: 285px;
    height: 190px;
}

.home-collection-text {
    background-color: #f5f5f5;
    padding: 15px 25px;
    border-radius: 10px;
}

#home-collections-carousel > .ant-carousel > .slick-slider > .slick-dots-right {
    right: -10px !important;
}

#prmoted-collections-carousel
    > .ant-carousel
    > .slick-slider
    > .slick-dots-right {
    right: -10px !important;
    z-index: 1;
}

.done-img {
    width: 214px;
}

.watch-img {
    width: 200px;
}

.analyze-img {
    width: 260px;
}

.home-img-caption-title {
    font-size: 20px;
    font-family: "Inter";
    font-weight: 500;
}

.home-img-caption {
    text-align: center;
    width: 260px;
    font-size: 12px;
    font-family: "Inter";
    font-weight: 300;
}

#home-instructors-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 50px 0px 50px;
}

#home-instructors-section {
    width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 50px;
}

#home-instructors-section > div > img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

#home-instructors-section-text {
    width: 350px;
}

#home-instructors-section-blockquote {
    width: 300px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#home-instructors-section-text > h5,
#home-learners-section-text > h5 {
    display: inline-block;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 500;
    color: #969696;
}

#home-instructors-section-text > p {
    font-family: "Inter";
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
}

#home-instructors-btn {
    display: inline-block;
    background: #0f95f5;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 12px;
    font-family: "Inter";
    font-weight: 700;
    color: white;
    transition: all 0.2s ease-in;
}

#home-instructors-btn:hover {
    background-color: #0f95f588;
}

.home-instructor-quote-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.home-instructor-avatar {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

#home-quote-carousel-container
    > .ant-carousel
    > .slick-slider
    > .slick-dots
    > li
    > button {
    background-color: #30b0a57d;
}

.home-instructor-quote {
    width: 350px;
    padding: 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 5px;
    height: 500px;
}

#home-quote-carousel-container {
    margin: 0 auto;
    width: 350px;
}

.home-instructor-quote-credits {
    background-color: #273745;
    width: 350px;
    padding: 40px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.home-instructor-credit {
    display: flex;
    flex-direction: column;
}

.home-instructor-credit > h1 {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    color: white;
}

.home-instructor-credit > p {
    margin: 0;
    color: rgb(177, 177, 177);
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
}

.home-instructor-quote > blockquote {
    display: inline-block;
    font-size: 18px;
    font-family: "Inter";
    font-weight: 700;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.642);
    border-left: 4px solid #30b0a57d;
    padding-left: 20px;
}

#home-learners-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0px 20px;
    background-color: #faf9f9;
}

#home-learners-section {
    width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px;
    gap: 50px;
}

#home-learners-section > div > img {
    width: 100%;
}

#home-learners-section-text {
    text-align: right;
    width: 350px;
}

#home-learners-section-text > p {
    font-family: "Inter";
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
}

#home-learners-watch-btn {
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
    padding: 5px 20px;
    background-color: #0f95f5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

#home-learners-watch-btn:hover {
    background-color: #0f95f588;
}

/* ------- END ------- */

/* -------- Creator Intro Page -------- */
.creator-intro-section {
    background-color: #f0f2f5;
    padding: 20vh 7vw;
}

.creator-intro-hook {
    width: 360px;
}

.creator-intro-hook > h1 {
    display: inline-block;
    font-size: 30px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 33px;
    color: #000000;
}

.creator-intro-hook > h3 {
    font-size: 40px;
    font-family: "Inter";
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-top: -80px;
    margin-bottom: 0;
}

.creator-intro-hook > h6 {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 300;
    font-style: italic;
    color: #000000;
}

.creator-intro-hook > p {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 300;
    color: #000000;
}

.creator-intro-right-aligned {
    text-align: right;
}

.creator-intro-first {
    background-color: #f0f2f5;
    margin-bottom: 150px;
}

.creator-intro-second {
    background: #01161e;
    padding: 20px 20px;
}

.creator-intro-third {
    background-color: rgba(0, 0, 0, 0.075);
}

.creator-intro-fourth {
    background-color: rgba(0, 0, 0, 0.075);
}

.creator-intro-fifth {
    background-color: #faf9f9;
    padding: 20px 20px;
}

.creator-intro-sixth {
    background-color: #273745;
    padding: 50px 7vw;
}

.creator-benefits-text {
    color: white;
    font-size: 22px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 26px;
}

.creator-benefits-one {
    padding: 25px 25px;
    background-color: #273745;
}

.creator-benefits-two {
    padding: 25px 25px;
    background-color: #31467c;
    text-align: right;
}

.creator-benefits-three {
    padding: 25px 25px;
    background-color: #7b9ea8;
}

.creator-explanations-card {
    padding: 50px;
    background: #faf9f9;
    border-radius: 10px;
    width: 340px;
    margin: 0 auto;
}

.creator-explanations-card > h3 {
    font-size: 22px;
    font-family: "Inter";
    font-weight: 700;
    line-height: 26px;
}

.creator-explanations-card > p {
    font-size: 22px;
    font-family: "Inter";
    font-weight: 300;
    line-height: 26px;
}

.creator-explanations-row-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 300px;
}

.creator-explanations-row-two {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 300px;
}

.creator-explanations-row-three {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 300px;
}

.creator-explanations-row-four {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 50px;
}

#creator-faq-card-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    row-gap: 50px;
    margin-bottom: 100px;
}

.creator-faq-section > h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
}

.creator-faq-card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 575px;
}

.creator-faq-card > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.creator-faq-card > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

#creator-end-hook {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
    color: white;
}

.home-collection-view-btn {
    background: #509f83;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    transition: all 0.2s ease-in;
    border: 1px solid #f5f5f5;
}

.home-collection-view-btn:hover {
    background-color: white;
    border: 1px solid #509f83;
    color: #509f83;
}

#creator-create-collections-steps {
    padding: 10px 10px;
}

#creator-create-collections-steps > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

#creator-create-collections-steps > ol {
    list-style: none;
    padding: 0;
}

.creator-create-collections-step-one {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #273745;
    padding: 5px 10px;
    margin-top: 10px;
}

.creator-create-collections-step-one > p,
.creator-create-collections-step-two > p,
.creator-create-collections-step-three > p {
    margin: 0;
    color: white;
}

.creator-create-collections-step-two {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #31467c;
    padding: 5px 10px;
    margin-top: 10px;
}

.creator-create-collections-step-three {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    background-color: #7b9ea8;
    margin-top: 10px;
}

.creator-create-collections-step-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.creator-create-collections-step-label {
    border: 2px solid white;
    border-radius: 100px;
    padding: 4px 8px;
    color: white;
    font-size: 15px;
    line-height: 18px;
}

/* -------- END --------- */

.faq-card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.ant-card-body {
    padding: 10px;
}

.text-color-green {
    color: #33bcb7;
}

#collection-show,
#wallet-dashboard,
#creator-author-page-header {
    padding: 0px 7vw;
}

#creator-panel,
#premium-collection-mid-section,
#promoted-collection-mid-section,
#purchased-content-page,
#premium-collection-mid-section,
.centered-container,
#report-issue-page,
#scheduled-licenses-page {
    margin: 10px 7vw;
}

.collection-info-card {
    margin: 10px 0px;
}

.collection-info-card-text-container {
    padding: 0px;
}

.ant-typography.collection-info-card-title {
    margin: 0;
    font-size: 14px;
}

.collection-info-card-loading {
    margin-bottom: 25px;
}

.collection-info-card-description,
.collection-info-card-long-description {
    display: none;
}

.ant-page-header,
.collection-list-item {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.ant-page-header.import-collection-modal {
    box-shadow: none;
    padding: 0;
}

#collection-show > .ant-page-header {
    box-shadow: 3px -8px 25px rgba(0, 0, 0, 0.2);
}

#collection-show > .module-list {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#video-content-wrapper {
    position: relative;
    max-height: calc(100vh - 48px - 98px - 70px);
    background-color: black;
    overflow: none;
}

#navigation-links {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#navigation-links > .ant-space-item {
    height: 48px;
}

.module-video-list {
    position: relative;
    overflow: auto;
    /* bottom padding to account for shadow cutoff on card hover */
    padding-bottom: 10px;
}

.loading-indicator {
    margin: auto;
    width: 50%;
}

.transcript-segment {
    color: rgb(0, 0, 0);
    font-size: 14px;
    line-height: 25px;
}

.transcript-segment:hover,
#highlighted-transcript-segment:hover {
    cursor: pointer;
    //color: #43f2ec;
    color: rgb(53, 156, 105);
}

#transcript-tab-container {
    background-color: white;
}

#highlighted-transcript-segment {
    color: rgb(53, 156, 105);
}

.greyed-card {
    background-color: grey;
    opacity: 0.5;
}

#stripe-badge {
    width: 110px;
}

.empty-transcript-message {
    color: white;
}

.scheduled-license-col > .ant-list > .ant-list-header {
    background: #001529d7;
    color: white;
}

#transcript-tab-container > .ant-tabs-nav {
    margin: 0 15px;
}

#transcript-tab-container
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab {
    margin: 0 16px 0 0;
}

#transcript-tab-container
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(4) {
    margin-left: 64% !important;
}

.promo-gradient-btn {
    border-radius: 5px;
    padding: 8px 8px;
    font-family: "Lato", sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
        7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
}

.animation-btn {
    border: none;
    background: rgb(10 166 121);
    background: linear-gradient(
        0deg,
        rgba(10 166 121, 1) 0%,
        rgba(10 166 121, 1) 100%
    );
    color: #fff;
    overflow: hidden;
}
.animation-btn:hover {
    text-decoration: none;
    color: #fff;
}
.animation-btn:before {
    position: absolute;
    content: "";
    display: inline-block;
    top: -52px;
    left: 0;
    width: 7px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 5s ease-in-out infinite;
}
.animation-btn:hover {
    opacity: 0.7;
}
.animation-btn:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
        -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
        inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn1 {
    0% {
        transform: scale(0) rotate(45deg);
        opacity: 0;
    }
    80% {
        transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }
    81% {
        transform: scale(4) rotate(45deg);
        opacity: 1;
    }
    100% {
        transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}

@-webkit-keyframes shiny-btn1 {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }
    81% {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}

#video-page-layout {
    display: flex;
    align-items: stretch;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.12);
}

.stripe-card-input {
    background: white;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
}

.stripe-card-input:focus {
    box-shadow: 0 4px 8px 0 rgba(6, 143, 255, 0.2);
}

.stripe-card-error {
    box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.63);
}

.card-error {
    font-size: 15px;
    font-weight: 500;
    color: rgb(255, 79, 79);
    height: 25px;
}

#top-up-form,
#payment-form {
    padding: 25px;
}

#stripe-container {
    border-radius: 5px;
    padding: 20px 0px 0px 0px;
    margin-top: 5px;
}

#stripe-pay-now {
    width: 100%;
    background: #556cd6;
    border-color: #556cd6;
}

.quiz-option {
    margin: 10px 0px;
    padding: 25px;
    border: 1px solid rgb(219, 219, 219);
    transition: all 0.2s linear;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}

.quiz-option:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.quiz-option > span > pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.selected-quiz-option {
    border: 1px solid #33bcb7;
}

.correct-quiz-option {
    border: 1px solid #64ca6d;
}

.correct-answer-ribbon {
    background-color: #64ca6d;
}

.correct-quiz-option:hover,
.wrong-quiz-option:hover,
.non-clickable-option:hover {
    box-shadow: none;
    cursor: default;
}

.wrong-quiz-option {
    border: 1px solid rgb(214, 113, 99);
}

.not-full-correct-quiz-option {
    border: 1px solid rgb(211, 190, 96);
}

.question-legend-dot-green {
    border: 2px solid #52c41a;
    background-color: white;
    border-radius: 100px;
    width: 10px;
    height: 10px;
}

.quiz-module-purchase-headers {
    width: 132.5px;
    text-align: center;
    color: white;
}

.quiz-module-purchase-headers-text {
    color: white;
}

.page-header-without-bottom-padding {
    padding-bottom: 0px;
}

.quiz-module-purchase-headers-container > .ant-card-body {
    padding: 5px 0px 5px 16px;
}

.quiz-module-purchase-headers-container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #001529;
    padding: 5px 24px;
}

#demo-request-page {
    background-color: white;
    padding: 40px;
    width: 100%;
    margin: 100px auto;
    border-radius: 10px;
}

#demo-request-page > h1 {
    font-family: "Inter";
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
}

#demo-request-page > h4 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    margin-bottom: 25px;
}

#demo-request-step-two > h6 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 50px;
    margin-bottom: 25px;
}

#demo-request-back-btn {
    background: #ffffff;
    border-radius: 5px;
    color: #000;
    padding: 8px 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
    border: 1px solid #bcbcbc;
}

#demo-request-next-btn {
    background: #0f95f5;
    border-radius: 5px;
    color: white;
    padding: 8px 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
}

#demo-request-next-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// TODO -- FIX MERGE

#header {
    position: fixed;
    z-index: 3;
    width: 100%;
    padding: 0px 7vw;
    height: 48px;
    line-height: 48px;
}

#header > div > .logo {
    float: left;
    margin: -2px 30px 0px 0px;
    font-size: 25px;
}

#header > div > .logo > img {
    width: 100px;
    height: 40px;
}

#header .cubits-logo-anchor {
    display: flex;
    align-items: center;
}

.login-form {
    width: 90%;
    margin: 0px auto;
}

.login-form-button {
    width: 100%;
}

.ant-card-body {
    padding: 10px;
}

.cannot-be-found {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

#landing-section {
    height: 80vh;
    padding: 7vw;
}

#landing-section > div > h1,
#landing-section > div > h5,
#landing-section > div > p {
    color: white;
}

.text-color-green {
    color: #33bcb7;
}

#collection-show,
#collection-list,
#wallet-dashboard,
#module-pool-container,
#collection-creator-container,
#module-creator-container,
#creator-application-form-page {
    padding: 0px 7vw;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: calc(100% - 48px);
    background-color: black;
}

#video-content-wrapper {
    max-height: 100vh;
}

#video-wrapper iframe,
#player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.module-video-list {
    position: relative;
    overflow: auto;
    /* bottom padding to account for shadow cutoff on card hover */
    padding-bottom: 10px;
}

.greyed-card {
    background-color: grey;
    opacity: 0.5;
}

#stripe-badge {
    width: 110px;
}

.empty-transcript-message {
    color: white;
}

.scheduled-license-col > .ant-list > .ant-list-header {
    background: #001529d7;
}

#transcript-tab-container > .ant-tabs-nav {
    margin: 0 15px;
}

#transcript-tab-content {
    height: calc(100vh - 185px);
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    text-align: left;
    letter-spacing: 0.5px;
    position: relative;
    scroll-behavior: smooth;
    padding: 10px 15px;
    margin: 5px 0px;
}

#transcript-tab-content.quiz-question-creator-transcript {
    height: calc(50vh);
}

#transcript-tab-content::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

#video-page-menu {
    overflow: auto;
    background-color: white;
}

#module-pool-list {
    margin-top: 72px;
}

#module-pool-page-header,
#collection-creator-header,
#module-creator-header {
    position: fixed;
    z-index: 1;
    width: calc(100% - 14vw);
    box-shadow: 0px 0px 3px rgba(24, 24, 24, 0.37);
}

.module-pool-video-card {
    max-width: 480px;
}

.module-pool-video-card > .ant-card-actions > li:last-child {
    color: #33bcb7;
}

.module-pool-video-player > .ant-modal-content,
.ant-modal-body {
    padding: 0px;
}

.user-created-modules-list-item {
    padding: 20px;
}

#creator-panel-tabs > .ant-tabs-nav {
    margin-bottom: 0px;
}

#add-video-by-playlist-or-video-url-form,
#create-scheduled-license-form {
    padding: 25px;
}

.created-module-title {
    display: flex;
    justify-content: space-between;
}

.module-pool-video-player-modal-content {
    background-color: #000;
    display: flex;
    flex: auto;
    min-height: 0;
    align-items: center;
}

#transcript-in-modal {
    width: 40%;
    display: inline-block;
    height: 710px;
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    text-align: left;
    letter-spacing: 0.5px;
    position: relative;
    scroll-behavior: smooth;
    padding: 10px 15px;
}

#transcript-in-modal::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.module-pool-video-player > .ant-modal-content > .ant-modal-footer {
    text-align: left;
}

.player-in-modal {
    width: 60%;
    display: inline-block;
    background-color: black;
    height: 100%;
}

.player-in-modal > #vimeo-player > iframe {
    width: 100%;
}

#new-collection-form,
#new-module-form {
    width: 65%;
    display: inline-block;
    padding: 15px;
}

#new-collection-form-container,
#new-module-form-container {
    margin-top: 72px;
    vertical-align: top;
}

#imported-videos-container {
    margin-left: 3%;
    width: 27%;
    display: inline-block;
    height: 75vh;
    max-height: 75vh;
    position: fixed;
}

#imported-videos-container > .ant-card-head {
    background-color: #001529;
    color: white;
    min-height: 40px;
}

#imported-videos-container
    > .ant-card-head
    > .ant-card-head-wrapper
    > .ant-card-head-title {
    padding: 12px 0px;
}

#imported-videos-container > .ant-card-body {
    padding: 0px;
}

#imported-videos-scroll-container {
    height: calc(75vh - 50px - 62px);
    overflow-x: hidden;
    overflow-y: auto;
}

#imported-video-cards-wrapper {
    height: 100%;
    padding: 24px;
}

.new-collection-form-module-card {
    padding: 15px;
    //background-color: #fafafa;
}

.imported-video-card > .ant-card-cover {
    height: calc(100% - 42px);
}

.imported-video-card > .ant-card-body {
    max-height: 42px;
}

.imported-video-card:hover {
    cursor: pointer;
}

.video-selected {
    border: 5px solid #33bcb7 !important;
}

.highlighted-video-list {
    border: 1px dashed #33bcb7;
    cursor: pointer;
}

.highlighted-video-list-blur {
    background-color: #fff;
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px);
    -o-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
}

.videos-in-module-list {
    position: relative;
    padding: 5px 5px 0px 5px;
    height: 400px;
    background-color: rgb(230, 230, 230);
}

.videos-in-module-list > .ant-card-body {
    overflow-x: hidden;
}

.click-to-add-selected-video-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.37);
}

.click-to-add-selected-video-message > .ant-typography {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    color: white;
    margin: 0;
}

.hidden {
    display: none;
}

.video-card-in-module-collection-creator {
    margin-bottom: 0px;
}

.selected-collection-page-header-in-collection-creator {
    padding: 0px;
}

.loading-indicator-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

#demo-requests-list-container {
    width: 100%;
    margin: 0 auto;
}

#demo-requests-list-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #273745;
    align-items: flex-start;
    margin: 25px 0px 25px;
    border-radius: 5px;
}

#demo-requests-list-header > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: white;
    padding: 15px 30px;
    margin: 0;
}

.demo-request-card-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}

.demo-request-card-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 30px;
}

.demo-request-drawer-component.ant-drawer-open > .ant-drawer-content-wrapper {
    width: 100%;
}

.demo-request-card {
    background-color: rgba(255, 255, 255, 0.52);
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 25px 30px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.demo-request-card:hover {
    border-color: #0f95f5;
    background-color: rgb(255, 255, 255);
}

.demo-request-card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.demo-request-card-footer > div > p {
    margin: 0;
}

.demo-request-main-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
}

.demo-request-metadata {
    background: #31467c;
    border-radius: 5px;
    color: white;
    padding: 15px 25px;
}

#demo-requests-sort-dropdown {
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 0px 30px 15px;
}

#demo-requests-sort-dropdown > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: white;
    margin: 0;
}

.demo-request-metadata-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
}

.demo-request-drawer {
    padding: 0;
}

.demo-request-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 40px;
}

.demo-request-metadata-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
}

.demo-request-copy-email {
    color: white;
}

.demo-request-video-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.demo-request-video-list-header > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
}

.demo-request-video-links-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

.demo-request-links-container {
    margin: 30px 0px 30px 0px;
}

.demo-request-link-btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

.demo-request-link-btn-invalid {
    color: rgb(0, 0, 0);
}

.demo-request-download-all-files {
    background: #509f83;
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.demo-request-download-all-files-disabled {
    background: #f5f5f5;
    border: 1px solid rgba(173, 173, 173, 0.708);
    border-radius: 5px;
    padding: 5px 10px;
    color: rgba(173, 173, 173, 0.708);
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.demo-request-download-all-files:hover {
    background: #509f83bd;
}

.demo-request-video-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #d7d2d2;
    border-radius: 5px;
    padding: 10px 15px;
    align-items: center;
    margin-bottom: 10px;
    transition: 0.2s all ease-in-out;
}

.demo-request-video-row:hover {
    border: 1px solid #0f95f5;
}

.demo-request-video-row:hover > p {
    color: #0f95f5;
}

.demo-request-video-row-link {
    color: black;
}

.demo-request-video-row > p {
    margin: 0;
}

.demo-request-video-download-btn {
    background: #509f83;
    border-radius: 5px;
    border: none;
    padding: 0px 8px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.demo-request-video-download-btn:hover {
    background: #509f83b3;
}

.demo-request-course-details {
    margin: 30px 0px 30px;
}

.demo-request-course-detail-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

.demo-request-course-detail-collection-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.demo-request-course-detail-value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.upload-cover-image {
    width: 100%;
    height: auto;
    position: relative;
}

.upload-cover-image > .ant-upload {
    width: 100%;
    height: auto;
}

.upload-cover-image-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
}

.upload-cover-image-hover-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.336);
    opacity: 0;
    transition: opacity 0.2s;
}

.upload-cover-image-hover-div:hover {
    opacity: 0.8;
}

.upload-cover-image-hover-div > .ant-typography {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
}

.modules-component > .ant-card-body {
    padding: 0;
}

.modules-component-video-list-container {
    position: relative;
}

.modules-component-video-list-container > .ant-card-body {
    overflow-x: hidden;
}

.module-descriptions-card {
    height: 100%;
}

.module-descriptions-card > .ant-card-body {
    height: calc(100% - 47px);
}

.modules-component-video-card {
    width: 300px;
    height: 200px;
}

.import-content-from-modal > .ant-modal-content > .ant-modal-body {
    padding: 0px;
}

.centered-on-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

#creator-application-form {
    margin: 0 auto;
}

#existing-questions-container {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

.module-form-collapse-panel
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0;
}

.white-color-text {
    color: white;
}

.disable-highlighting {
    /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
}

.schedule-accesses-container {
    height: 200px;
    overflow-y: auto;
    padding: 15px;
    border: 1px solid black;
}

.hugo {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
}

.hoverable {
    cursor: pointer;
}

.form-error-list {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove padding */
    margin: 0;
    /* Remove margins */
}

.bold-error-list-item {
    font-weight: bold;
}

.normal-error-list-item {
    font-weight: normal;
}

.rich-editor {
    border: 1px solid #ddd;
}

.rich-editor-input {
    padding: 15px;
}

.rich-editor-buttons {
    border-bottom: 1px solid #ddd;
}

.selected-rich-editor-button {
    background: rgba(0, 0, 0, 0.11);
}

.selected-rich-editor-button:focus {
    background: rgba(0, 0, 0, 0.11);
}

.selected-rich-editor-button:hover {
    background: rgba(0, 0, 0, 0.11);
}

.rich-editor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.rich-editor-hide-placeholder {
    display: none;
}

.rich-editor-code-block {
    padding: 2px 5px;
    color: rgba(0, 0, 0, 0.473);
    background-color: rgba(245, 246, 247, 0.904);
    margin-bottom: 0px;
}

.question-text-image-upload-div {
    border: 1px solid #ddd;
    padding: 10px 10px 0px 10px;
}

.public-DraftStyleDefault-pre {
    background-color: rgba(242, 242, 242, 0.979);
    padding: 10px;
}

.quizquestion-carousel-width {
    width: 100%;
    margin: 0 auto;
}

.quizquestion-drawer-content-width {
    width: 90%;
    margin: 0 auto;
}

.standard-modal-content {
    padding: 25px;
}

.quiz-questions-direct-buttons {
    width: 100%;
    background-color: black;
    height: 48px;
    padding: 10px;
}

.quiz-questions-direct-buttons-scrollable-container {
    display: inline-block;
    margin-left: 10px;
    width: 300px;
    position: relative;
    overflow: auto;
}

.quiz-questions-direct-buttons-container {
    width: 100%;
}

#video-page-extra-content-layout {
    min-height: 100vh;
}

#q-and-a-section {
    margin-left: 15vw;
    width: calc(100% - 45vw);
    padding: 48px 25px 25px 25px;
}

#q-n-a-instructor-square {
    background-color: #868cbe;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    display: inline-block;
    margin-top: 5px;
}

.instructor-color {
    color: #868cbe;
}

#q-n-a-header {
    display: flex;
    justify-content: space-between;
}

.qna-reply-form-container {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.qna-reply-username {
    font-size: 12px;
}

.qna-replies {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.visible-qna-replies {
    display: block;
}

.qna-sub-heading {
    font-size: 12px;
    color: #595959;
}

.spin-container {
    margin: 20px 0;
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.highlight-new-comment {
    -webkit-animation: highlightcomment linear 1s 1;
    animation: highlightcomment 0.2s linear 0.5s 1;
}

@keyframes highlightcomment {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes highlightcomment {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

#search-module-video-list {
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
}

#search-module-video-list::-webkit-scrollbar {
    height: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

.search-module-video-card-container {
    padding: 15px;
}

.search-module-video-card-container:hover {
    cursor: pointer;
}

.search-module-video-card-container-selected {
    background-color: #001529;
}

.collection-search-keyword-highlight {
    color: #33bcb7;
    background-color: transparent !important;
    padding: 0;
}

.collection-search-results-video-container {
    background-color: #1a5c5a;
    position: relative;
}

.collection-search-video-transcripts-container {
    padding: 20px;
}

.collection-search-keyword-unhighlight {
    color: black;
}

.collection-search-video-transcript-segment {
    display: flex;
    color: black;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    opacity: 0.7;
    padding: 5px 0px;
}

.collection-search-video-transcript-segment:hover {
    opacity: 1;
}

.collection-search-input-container {
    padding: 10px 0px;
    z-index: 3;
}

.timestamp-component {
    color: rgb(61, 156, 200);
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.timestamp-component:hover {
    color: rgb(123, 192, 224);
}

.qna-anonymous-spoiler-tag {
    position: relative;
    background-color: rgba(47, 47, 47, 0.548);
    color: transparent;
    z-index: 2;
    padding: 1px 2px;
    opacity: 1;
    border-radius: 3px;
    transition: all 0.2s ease-in;
}

.qna-anonymous-spoiler-tag:hover {
    color: black;
    background-color: transparent;
}

.qna-anonymous-hover-to-reveal {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
    transition: all 0.2s ease-in;
}

.qna-anonymous-hover-to-reveal:hover {
    z-index: 0;
    opacity: 0;
}

.comment-nested-reply-delete {
    color: rgb(207, 138, 25) !important;
}

.comment-nested-remove-question {
    color: rgb(170, 35, 223) !important;
}

.comment-nested-delete-question {
    color: rgb(207, 138, 25) !important;
}

#report-issue-form {
    padding: 15px;
}

// dashboard
#dashboard-home {
    padding: 0px 50px;
}

#mock-dashboard-home {
    padding: 0px 50px;
}

.dashboard-card {
    padding: 15px 15px;
}

.dashboard-pageheader {
    padding-bottom: 0px;
}

.dashboard-pageheader > .ant-page-header-content {
    padding: 0px;
}

.video-page-affix-menu-qna-count {
    padding: 1px 6px;
    border-radius: 3px;
    color: white;
    background-color: rgb(189, 155, 189);
}

.quiz-question-status-indicator {
    display: inline-block;
    padding: 6px;
    border-radius: 3px;
}

.inactive-unanswered-quiz-question-indicator {
    background-color: rgb(167, 167, 167);
}

.active-unanswered-quiz-question-indicator {
    background-color: rgb(250, 237, 61);
}

.incorrect-submitted-quiz-question-indicator {
    background-color: rgb(238, 93, 93);
}

.correct-submitted-quiz-question-indicator {
    background-color: rgb(89, 251, 170);
}

.ant-list-item.dashboard-qna-question {
    background-color: #001529;

    color: white;
    border-radius: 3px;
    padding: 15px 15px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.ant-list-item.dashboard-qna-question.dashboard-qna-question-visible-replies {
    background-color: #001529ca;
}

.dashboard-qna-question-replies-statistic > .ant-statistic-title,
.dashboard-qna-question-replies-statistic > .ant-statistic-content {
    color: white;
}

.dashboard-qna-question-replies-statistic > .ant-statistic-title {
    margin: 0px;
}

.dashboard-qna-question-replies-statistic > .ant-statistic-content {
    line-height: 1;
    text-align: center;
}

.dashboard-qna-question-expanded-content {
    background-color: #f2f2f2;
    padding: 1px 0px 0px 55px;
    max-height: 0px;
    transition: all 0.1s ease-out;
    overflow: hidden;
}

.light-mode-rich-text-preview.rich-editor-preview {
    color: white;
}

.ant-list-item.dashboard-qna-answer {
    background-color: #8f9fae;
    color: white;
    border-radius: 3px;
    padding: 7px 20px;
}

.dashboard-qna-anonymous-spoiler-tag {
    position: relative;
    background-color: rgba(131, 131, 131, 0.548);
    color: transparent;
    z-index: 2;
    padding: 1px 2px;
    opacity: 1;
    border-radius: 3px;
    transition: all 0.2s ease-in;
}

.dashboard-qna-anonymous-spoiler-tag:hover {
    color: grey;
    background-color: transparent;
}

.dashboard-qna-anonymous-hover-to-reveal {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
    transition: all 0.2s ease-in;
}

.dashboard-qna-anonymous-hover-to-reveal:hover {
    z-index: 0;
    opacity: 0;
}

.ant-menu-submenu-disable {
    color: rgba(0, 0, 0, 0.499) !important;
}

.dashboard-qna-answer-anonymous-spoiler-tag {
    position: relative;
    background-color: rgba(131, 131, 131, 0.548);
    color: transparent;
    z-index: 2;
    padding: 1px 2px;
    opacity: 1;
    border-radius: 3px;
    transition: all 0.2s ease-in;
}

.dashboard-qna-answer-anonymous-spoiler-tag:hover {
    color: lightgrey;
    background-color: transparent;
}

.dashboard-qna-answer-anonymous-hover-to-reveal {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
    transition: all 0.2s ease-in;
}

.dashboard-qna-answer-anonymous-hover-to-reveal:hover {
    z-index: 0;
    opacity: 0;
}

.dashboard-qna-reply-normal-highlight {
    border-left: 5px solid white;
}

.dashboard-qna-reply-highlight-recent {
    border-left: 5px solid rgb(89, 251, 170);
}

.dashboard-qna-question-normal-highlight {
    border-left: 5px solid lightgrey;
}

.dashboard-qna-question-highlight-recent {
    border-left: 5px solid rgb(89, 251, 170);
}

.recent-qna-posting-legend {
    padding: 6px;
    border-radius: 3px;
    background-color: rgb(89, 251, 170);
    display: inline-block;
}

.quiz-section-module-header {
    background-color: #8f9fae;
    padding: 10px;
    border-radius: 2px;
    color: white;
    cursor: pointer;
}

.quiz-section-video-row {
    background-color: rgb(253, 253, 253);
    padding: 10px 0px 10px 25px;
    border-radius: 2px;
    border: 0.5px solid rgb(226, 226, 226);
}

.quiz-section-video-column-headers {
    background-color: rgb(230, 230, 230);
    padding: 5px 0px 5px 25px;
    border-radius: 2px;
}

.quiz-section-video-container {
    padding: 0px 0px 0px 25px;
}

.quiz-section-video-column-headers-text {
    color: rgb(168, 168, 168);
}

.quiz-section-quiz-header {
    background-color: #8f9fae;
    padding: 10px;
    border-radius: 2px;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: 1px 0px 0px 0px;
    cursor: pointer;
}

.dashboard-quiz-section-question-attempts-container {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.1s ease-in;
}

.qna-section-collection-container {
    background-color: #272f41;
    border-radius: 2px;
}

.qna-section-collection-row {
    background-color: #272f41;
    padding: 10px 15px;
    color: white;
}

.qna-section-modules-container {
    background-color: #272f41;
    padding: 0px 0px 0px 15px;
}

.qna-section-collection-column-headers {
    background-color: rgb(230, 230, 230);
    padding: 5px 15px 5px 15px;
    border-radius: 2px;
}

.qna-section-module-column-headers {
    background-color: rgb(230, 230, 230);
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
}

.qna-section-video-container {
    padding: 0px 0px 0px 25px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.1s ease-in;
}

#creator-intro-footnote {
    margin-top: 40px;
    font-size: 10px;
}

#creator-intro-footnote > span {
    font-size: 15px;
}

#about-us-page {
    padding: 25px 7vw;
}

.about-us-paragraph {
    width: 100%;
}

.dashboard-video-grid-statistic > .ant-statistic-title {
    margin: 0px;
}

.dashboard-video-grid-statistic > .ant-statistic-content {
    font-size: 15px;
}

.dashboard-video-grid-statistic
    > .ant-skeleton
    > .ant-skeleton-content
    > .ant-skeleton-title {
    margin: 5px 0px 5px 0px;
    width: 60%;
}

.quiz-question-statistics-card {
    height: 100%;
}

.quiz-question-statistics-card > .ant-card-body::before,
.quiz-question-statistics-card > .ant-card-body::after {
    display: none;
}

.quiz-question-statistics-card > .ant-card-body {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.empty-list-container {
    border: 1px solid #f6f6f6;
    padding: 30px;
}

.creator-author-page-card {
    padding: 15px;
}

.edit-icon {
    color: #33bcb7;
    cursor: pointer;
}

.avatar-uploader > .ant-upload {
    width: 180px;
    height: 180px;
}

.modal-body {
    padding: 25px;
}

.collection-creator-steps-container {
    margin: 15px 0px 0px;
}

.ant-list-item.created-modules-list-item {
    border-bottom: none;
}

.coupon-module-dates-available {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.coupon-module-dates-available.coupon-module-dates-available-header {
    border: 1px solid #303030;
    background: #141414;
    border-radius: 2px;
}

.loading-video-page {
    display: flex;
    align-items: stretch;
    max-height: 80vh;
    border-top: 0.5px solid rgba(255, 255, 255, 0.12);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.12);
}

.loading-video {
    width: 100%;
    height: 80vh;
}

.loading-video-page-header {
    padding: 8px 16px;
}

.loading-video-page-menu {
    width: 15vw;
    background-color: #1f1f1f;
}

.loading-video-page-transcript-menu {
    width: 30vw;
    background-color: #1f1f1f;
}

.premium-collection-list-item {
    margin-bottom: 25px;
}

.loading-authentication {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.creator-page-anchor-container {
    display: none;
}

.creator-profile-picture {
    display: block;
    margin: auto;
}

.module-list-button {
    width: 38px;
}

.collection-search-selected-video {
    border: 1px solid #018060;
}

.module-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.collection-search-input-box {
    width: 100%;
}

.collection-info-card-action-button {
    width: 100%;
}

.testimonies-section {
    margin-top: 200px;
    width: 100%;
}

.ant-typography ul li.faq-item {
    margin: 20px 0px;
}

.ant-space-responsive {
    display: inline-block;
    width: 100%;
}

.ant-space-responsive > .ant-space-item > button {
    width: 100%;
    margin-bottom: 10px;
}

.ant-space-responsive > .ant-space-item > .ant-breadcrumb {
    margin-bottom: 10px;
}

.main-menu-list {
    display: none;
}

.main-menu-list-container {
    width: 100%;
}

#unauthenticated-login-register-btn-wrapper {
    display: none;
}

.unauthenticated-menu {
    display: inline-flex;
}

.coupon-schedule > .ant-list-item-action {
    margin-left: 0;
}

.date-tag > kbd {
    margin: 0;
}

.creator-collection-performance-detail > .ant-drawer-content-wrapper {
    width: 100% !important;
}

.dashboard-home-anchor {
    width: 200px;
    display: none;
}

.dashboard-content {
    width: 100%;
}

.class-coupon-dropdown {
    min-width: 300px;
}

.collection-creator-container,
.module-creator-container,
.collection-editor-container {
    display: none;
}

.collection-creator-container-mobile,
.module-creator-container-mobile,
.collection-editor-container-mobile {
    display: block;
}

.centered-card {
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.centered-card:hover {
    background-color: #509f83;
}

.centered-card:hover > .ant-card-body > span,
.centered-card:hover > .ant-card-body > h1 {
    color: white;
}

.centered-card:not(.disabled-centered-card):hover h1,
.centered-card:not(.disabled-centered-card):hover span {
    color: white;
}

.centered-card.disabled-centered-card {
    cursor: not-allowed;
}

.centered-card.disabled-centered-card:hover {
    background-color: initial;
}

.centered-card.disabled-centered-card:hover > .ant-card-body > span,
.centered-card.disabled-centered-card:hover > .ant-card-body > h1 {
    color: black;
}

#transcript-uploader {
    width: 100%;
    text-align: center;
    background-color: #303030;
    border: 1px dashed white;
    height: 150px;
    opacity: 0.7;
    transition: all 0.1s ease-in;
}

#transcript-uploader:hover {
    opacity: 1;
}

.add-module-modal {
    padding: 15px;
}

.add-module-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 1px solid white;
    cursor: pointer;
}

.modules-to-import-list {
    width: 100%;
}

.selected-module-to-import {
    border: 1px solid green;
}

.selectable-module-option {
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.selectable-module-option:hover {
    border: 1px solid rgba(0, 128, 0, 0.698);
}

.created-modules-list-item {
    width: 100%;
}

#contact-us-form-container {
    padding: 10px 25px;

    border-radius: 2px;
    margin-bottom: 50px;
}

#contact-us-form {
    margin: 0 auto;
    max-width: 1000px;
}

.contact-us-form-input {
    background-color: #ffffff;
}

.demo-request-status-tag {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.centered-card {
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.centered-card:hover {
    background-color: #018060;
}

.centered-card.disabled-centered-card {
    cursor: not-allowed;
}

.centered-card.disabled-centered-card:hover {
    background-color: initial;
}

#transcript-uploader {
    width: 100%;
    text-align: center;
    background-color: #303030;
    border: 1px dashed white;
    height: 150px;
    opacity: 0.7;
    transition: all 0.1s ease-in;
}

#transcript-uploader:hover {
    opacity: 1;
}

#transcript-uploader > input[type="file"] {
    display: none;
}

#upload-transcript-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

#upload-confirm-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#upload-confirm-label > div {
    width: 100%;
}

#upload-confirm-label > div > .ant-btn {
    width: 80%;
}

#confirm-transcript-upload {
    width: 100%;
    text-align: center;
    background-color: #303030;
    height: 250px;
    opacity: 0.7;
    transition: all 0.1s ease-in;
}

#uploading-animation-container {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    animation: floating 3s infinite alternate;
}

#uploading-animation {
    font-size: 40px;
    animation: gliding 6s infinite forwards;
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-75px);
    }
}

@keyframes gliding {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    10% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    25% {
        -webkit-transform: rotate(-20deg);
        -moz-transform: rotate(-20deg);
        transform: rotate(-20deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    50% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    60% {
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    75% {
        -webkit-transform: rotate(20deg);
        -moz-transform: rotate(20deg);
        transform: rotate(20deg);
    }

    90% {
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

#footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    padding: 20px 7vw 100px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.147);
}

#footer-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
}

.footer-menu > h1 {
    font-family: "Inter";
    font-weight: 700;
    font-size: 18px;
    color: #444343;
}

.footer-menu > p,
.footer-menu > p > a {
    font-family: "Inter";
    font-weight: 300;
    font-size: 14px;
    margin: 0;
    color: #444343;
}

// Mobile Screens
@media only screen and (max-width: 768px) {
    #why-cubits-works.contain {
        padding: 100px 20px;
    }

    #how-you-get-paid.contain {
        padding: 100px 20px;
    }

    #how-you-get-paid img {
        width: 100%;
        margin: 36px 0;
    }

    .author-page-landing {
        flex-direction: column-reverse;
        text-align: center;
    }

    .author-page-landing .author-page-landing-btns {
        display: block;
    }
}

// Screen size: sm
@media screen and (min-width: 768px) {
    /* ------- Home ------- */
    .home-page-landing {
        width: 100%;
        padding: 20vh 7vw;
        display: flex;
        justify-content: space-between;
    }

    .home-category-section {
        width: 100%;
        padding: 3vh 7vw;
        display: flex;
        justify-content: center;
    }

    .author-page-landing {
        width: 100%;
        padding: 20vh 7vw;
        display: flex;
        justify-content: space-between;
    }

    .demo-request-drawer-component.ant-drawer-open
        > .ant-drawer-content-wrapper {
        width: 600px !important;
    }

    #demo-requests-list-header {
        flex-direction: row;
        align-items: center;
    }

    .demo-request-main-header {
        margin: 0;
    }

    .demo-request-main-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    #demo-requests-sort-dropdown {
        padding: 0px 0px 0px;
    }

    #demo-requests-sort-dropdown > p {
        font-size: 18px;
        line-height: 22px;
    }

    .demo-request-row {
        flex-direction: row;
        gap: 10px;
    }

    .demo-request-drawer {
        padding: 0px 50px;
    }

    #demo-request-page {
        width: 750px;
    }

    #demo-requests-list-container {
        width: 760px;
    }

    #demo-requests-list-header > h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: white;
        padding: 15px 30px;
    }

    #home-page-heading {
        width: 700px;
    }

    #home-page-heading > h1 {
        font-family: "Inter";
        font-size: 45px;
        font-weight: 500;
        line-height: 55px;
    }

    #author-page-heading {
        width: 700px;
    }

    #author-page-heading > h1 {
        font-family: "Inter";
        font-size: 45px;
        font-weight: 500;
        line-height: 55px;
    }

    #why-cubits-works {
        margin: 0 auto;
        text-align: center;
        padding: 100px 20px;
        background-color: #faf9f9;
    }

    #why-cubits-works > div {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    #how-you-get-paid {
        margin: 0 auto;
        text-align: center;
        padding: 100px 20px;
        background-color: initial;
    }

    #how-you-get-paid img {
        width: 100%;
        margin: 36px 0;
    }

    #how-you-get-paid > div {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }

    .home-collection-image-bio {
        display: flex;
        flex-direction: row;
    }

    .home-collection-one {
        background-color: #020b18;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection-two {
        background-color: #2e4d7d;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection-three {
        background-color: #ac2430;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection {
        padding: 40px 40px;
        background-color: white;
        margin-right: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .home-collection-image {
        width: 350px;
        height: 200px;
    }

    .ant-ribbon-wrapper {
        z-index: 2;
    }

    #home-instructors-section-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 50px 0px 50px;
    }

    #home-instructors-section {
        width: 1440px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 50px auto;
    }

    #home-instructors-section-text {
        width: 550px;
    }

    #home-instructors-section-blockquote {
        width: 300px;
        background-color: white;
        padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    #home-instructors-section-text > h5,
    #home-learners-section-text > h5 {
        display: inline-block;
        font-size: 15px;
        font-family: "Inter";
        font-weight: 500;
        color: #969696;
    }

    #home-instructors-section-text > p {
        font-family: "Inter";
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
    }

    .home-instructor-quote-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        height: 350px;
    }

    .home-instructor-quote {
        width: 500px;
        padding: 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        border-radius: 5px;
        height: 100%;
    }

    #home-quote-carousel-container {
        margin: 0 auto;
        width: 700px;
    }

    .home-instructor-quote-credits {
        background-color: #273745;
        width: 200px;
        padding: 40px 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    #home-learners-section-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 50px 0px 50px;
        background-color: #faf9f9;
    }

    #home-learners-section {
        width: 1440px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    #home-learners-section-text {
        text-align: right;
        width: 550px;
    }

    #home-learners-section-text > p {
        font-family: "Inter";
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
    }

    #home-learners-section-visual > img {
        max-width: 500px;
    }

    #home-learners-watch-btn {
        font-family: "Inter";
        font-size: 15px;
        font-weight: 500;
        padding: 5px 20px;
        background-color: #0f95f5;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in;
    }

    #home-learners-watch-btn:hover {
        background-color: #0f95f588;
    }

    /*---------- END ----------*/

    /* -------- Creator Intro Page -------- */

    #creator-create-collections-steps {
        padding: 70px 100px;
    }

    #creator-create-collections-steps > h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 30px;
    }

    .creator-create-collections-step-one,
    .creator-create-collections-step-two,
    .creator-create-collections-step-three {
        padding: 10px 20px;
    }

    .creator-create-collections-step-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }

    .creator-create-collections-step-label {
        border: 2px solid white;
        border-radius: 100px;
        padding: 4px 8px;
        color: white;
    }

    .creator-explanations-card {
        padding: 50px;
        background: #faf9f9;
        border-radius: 10px;
        width: 600px;
    }

    .creator-explanations-card > h3 {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 26px;
    }

    .creator-explanations-card > p {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 300;
        line-height: 26px;
    }

    .creator-explanations-row-one {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-two {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-three {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-four {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
    }

    /* -------- END --------- */

    /* ---- Collection Page ----- */

    .collection-page-section .collection-page-landing {
        width: 100%;
        padding: 20vh 7vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
    }

    .collection-page-section .collection-page-landing-image {
        width: 300px;
    }

    .collection-page-section #collection-page-heading {
        width: 300px;
    }

    .collection-page-section #collection-page-heading > h1 {
        font-family: "Inter";
        font-size: 25px;
        font-weight: 500;
        line-height: 30px;
        margin: 25px 0 15px 0;
    }

    .collection-page-section #collection-page-heading > p {
        font-family: "Inter";
        font-size: 18px;
        font-weight: 300;
        line-height: 22px;
    }

    .collection-page-section #collections-carousel .loading-section {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .collection-page-section #collections-carousel .certification-slider-item {
        padding: 0 4px;
        height: 100%;
    }

    .collection-page-section .slick-track {
        display: flex !important;
    }

    .collection-page-section .slick-slide {
        height: inherit !important;
    }

    .collection-page-section .slick-slide > div {
        height: 100%;
    }

    .collection-page-section .slick-prev:before,
    .collection-page-section .slick-next:before {
        opacity: 0.8;
        color: #30b0a5;
    }

    .collection-page-section .slick-prev.slick-disabled:before,
    .collection-page-section .slick-next.slick-disabled:before {
        color: #c5c5c5;
        cursor: default;
    }

    .collection-page-section .page-title {
        font-family: "Inter";
        font-size: 45px;
        font-weight: 500;
        line-height: 55px;
        text-align: center;
        margin-top: 3rem;
        padding: 0 calc(25vw);
    }

    .collection-page-section .page-title-2 {
        font-family: "Inter";
        font-size: 35px;
        font-weight: 300;
        line-height: 55px;
        text-align: center;
        padding: 0 calc(15vw);
        line-height: 1.2;
    }

    .collection-page-section .page-title-3 {
        font-family: "Inter";
        text-align: center;
        font-size: 22px;
        font-weight: 300;
        line-height: 22px;
    }

    .collection-page-section .explore-btn-section {
        padding: 1rem 0;
    }

    .certificate-hover-card-details .title {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0;
    }

    .certificate-sample {
        width: auto;
        border: thin dashed #d8dbe0;
    }

    /* -------- END --------- */

    .collection-info-card-description {
        display: block;
    }

    .ant-typography.collection-info-card-title {
        margin: 0;
        font-size: 18px;
    }

    .ant-space-responsive {
        display: inline-flex;
    }

    .ant-space-responsive > .ant-space-item > button {
        margin-bottom: 8px;
    }

    .ant-space-responsive > .ant-space-item > .ant-breadcrumb {
        margin-bottom: 0px;
    }

    .dashboard-home-anchor {
        display: block;
    }

    .dashboard-content {
        width: calc(100% - 200px - 20px);
    }

    .collection-creator-container,
    .module-creator-container,
    .collection-editor-container {
        display: block;
    }

    .collection-creator-container-mobile,
    .module-creator-container-mobile,
    .collection-editor-container-mobile {
        display: none;
    }

    .home-page-buttons.ant-space-responsive {
        width: auto;
    }

    #contact-us-form-container {
        padding: 20px 50px;
    }

    /* ----- FOOTER ------ */

    #footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        background-color: white;
        padding: 20px 7vw 100px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.147);
    }

    #footer-menu-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 100px;
    }

    .footer-menu > h1 {
        font-family: "Inter";
        font-weight: 700;
        font-size: 18px;
        color: #444343;
    }

    .footer-menu > p {
        font-family: "Inter";
        font-weight: 300;
        font-size: 14px;
        margin: 0;
        color: #444343;
    }

    /* ----- END ---------*/

    /* -------- Creator Intro Page -------- */
    .creator-intro-section {
        background-color: #f0f2f5;
        padding: 20vh 7vw;
    }

    .creator-intro-hook {
        width: 500px;
    }

    .creator-intro-hook > h1 {
        display: inline-block;
        font-size: 30px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 33px;
        color: #000000;
    }

    .creator-intro-hook > h3 {
        font-size: 40px;
        font-family: "Inter";
        font-weight: 500;
        color: #000000;
        text-align: center;
        margin-top: -80px;
        margin-bottom: 0;
    }

    .creator-intro-hook > h6 {
        font-size: 18px;
        font-family: "Inter";
        font-weight: 300;
        font-style: italic;
        color: #000000;
    }

    .creator-intro-hook > p {
        font-size: 18px;
        font-family: "Inter";
        font-weight: 300;
        color: #000000;
    }

    .creator-intro-right-aligned {
        text-align: right;
    }

    .creator-intro-first {
        background-color: #f0f2f5;
        margin-bottom: 150px;
    }

    .creator-intro-second {
        background: #01161e;
        padding: 130px 50px;
    }

    .creator-intro-third {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .creator-intro-fourth {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .creator-intro-fifth {
        background-color: #faf9f9;
        padding: 75px 115px;
    }

    .creator-benefits-text {
        color: white;
        font-size: 22px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 26px;
    }

    .creator-benefits-one {
        padding: 25px 25px;
        background-color: #273745;
    }

    .creator-benefits-two {
        padding: 25px 25px;
        background-color: #31467c;
        text-align: right;
    }

    .creator-benefits-three {
        padding: 25px 25px;
        background-color: #7b9ea8;
    }

    .creator-explanations-card {
        padding: 50px;
        background: #faf9f9;
        border-radius: 10px;
        width: 600px;
    }

    .creator-explanations-card > h3 {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 26px;
    }

    .creator-explanations-card > p {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 300;
        line-height: 26px;
    }

    .creator-explanations-row-one {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-two {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-three {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-four {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
    }

    /* -------- END --------- */
}

// Screen size: md
@media screen and (min-width: 975px) {
    /* ------- Home ------- */

    #home-instructors-section > div > img,
    #home-learners-section > div > img {
        width: 300px;
    }

    .home-page-landing {
        width: 100%;
        padding: 20vh 7vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
    }

    .home-category-section {
        width: 100%;
        padding: 3vh 7vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // gap: 50px;
    }

    .home-page-landing-image {
        width: 300px;
    }

    .author-page-landing {
        width: 100%;
        padding: 20vh 7vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
    }

    .author-page-landing-image {
        width: 300px;
    }

    #why-cubits-works > div {
        flex-direction: row;
        margin: 0 auto;
        gap: 30px;
        width: 930px;
        margin-top: 50px;
    }

    #why-cubits-works-watch {
        margin-top: 10px;
        background-color: #353535;
        padding: 20px 10px;
    }

    #why-cubits-works-watch > p {
        color: white;
    }

    #why-cubits-works-interact {
        margin-top: 10px;
        background-color: #7b9ea8;
        padding: 20px 10px;
    }

    #why-cubits-works-interact > p {
        color: white;
    }

    #why-cubits-works-analyze {
        margin-top: 10px;
        background-color: #31467c;
        padding: 20px 10px;
    }

    #why-cubits-works-analyze > p {
        color: white;
    }

    #how-you-get-paid > div {
        flex-direction: row;
        margin: 0 auto;
        gap: 30px;
        width: 930px;
        margin-top: 50px;
    }

    #how-you-get-paid-watch {
        margin-top: 10px;
        background-color: #353535;
        padding: 20px 10px;
    }

    #how-you-get-paid-watch > p {
        color: white;
    }

    #how-you-get-paid-interact {
        margin-top: 10px;
        background-color: #7b9ea8;
        padding: 20px 10px;
    }

    #how-you-get-paid-interact > p {
        color: white;
    }

    #how-you-get-paid-analyze {
        margin-top: 10px;
        background-color: #31467c;
        padding: 20px 10px;
    }

    #how-you-get-paid-analyze > p {
        color: white;
    }

    .home-collection-image-bio {
        display: flex;
        flex-direction: column;
    }

    .home-collection-one {
        background-color: #020b18;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection-two {
        background-color: #2e4d7d;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection-three {
        background-color: #ac2430;
        padding: 50px 50px;
        transition: all 0.3s ease-in;
    }

    .home-collection {
        padding: 40px 40px;
        background-color: white;
        margin-right: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .home-collection-image {
        width: 350px;
        height: 200px;
    }

    #home-instructors-section-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 50px 0px 50px;
    }

    #home-instructors-section {
        width: 1440px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 50px;
    }

    #home-instructors-section-text {
        width: 550px;
    }

    #home-instructors-section-blockquote {
        width: 300px;
        background-color: white;
        padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    #home-instructors-section-text > h5,
    #home-learners-section-text > h5 {
        display: inline-block;
        font-size: 15px;
        font-family: "Inter";
        font-weight: 500;
        color: #969696;
    }

    #home-instructors-section-text > p {
        font-family: "Inter";
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
    }

    .home-instructor-quote-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        height: 385px;
    }

    .home-instructor-quote {
        width: 500px;
        padding: 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        border-radius: 5px;
    }

    #home-quote-carousel-container {
        margin: 0 auto;
        width: 700px;
    }

    .home-instructor-quote-credits {
        background-color: #273745;
        width: 200px;
        padding: 40px 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    #home-learners-section-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 50px 0px 50px;
        background-color: #faf9f9;
    }

    #home-learners-section {
        width: 1440px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 50px;
    }

    #home-learners-section-text {
        text-align: right;
        width: 550px;
    }

    #home-learners-section-text > p {
        font-family: "Inter";
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
    }

    #home-learners-watch-btn {
        font-family: "Inter";
        font-size: 15px;
        font-weight: 500;
        padding: 5px 20px;
        background-color: #0f95f5;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in;
    }

    #home-learners-watch-btn:hover {
        background-color: #0f95f588;
    }

    /* ----- END ----- */
    .main-menu-list {
        display: flex;
    }

    #unauthenticated-login-register-btn-wrapper {
        display: flex;
    }

    .unauthenticated-menu {
        display: none;
    }

    .collection-info-card-text-container {
        padding: 0px 10px;
    }

    .module-header {
        flex-direction: row;
        justify-content: space-between;
    }

    .collection-search-input-box {
        width: 400px;
    }
}

@media screen and (min-width: 1000px) {
    .header-menu {
        display: flex;
    }
}

// Screen Size: lg
@media screen and (min-width: 1200px) {
    .collection-info-card-long-description {
        display: flex;
    }

    .creator-collection-performance-detail > .ant-drawer-content-wrapper {
        width: 1000px !important;
    }

    .testimonies-section {
        width: 800px;
        margin: 0 auto;
    }

    #contact-us-form-container {
        padding: 50px 100px;
    }

    #home-instructors-section > div > img,
    #home-learners-section > div > img {
        width: 500px;
    }

    .home-img-caption {
        text-align: center;
        width: 300px;
        font-size: 15px;
        font-family: "Inter";
        font-weight: 300;
    }
}

@media screen and (min-width: 1440px) {
    #why-cubits-works > div {
        gap: 100px;
        width: 1260px;
    }

    #how-you-get-paid > div {
        gap: 100px;
        width: 1260px;
    }

    .home-page-landing-image {
        width: 460px;
    }

    .home-page-landing {
        padding: 15vh calc((100% - 1200px) / 2);
    }

    .home-category-section {
        padding: 3vh calc((100% - 1200px) / 2);
    }

    .home-instructor-quote {
        width: 800px;
        padding: 15px 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        border-radius: 5px;
    }

    #home-quote-carousel-container {
        margin: 0 auto;
        width: 1000px;
    }

    .home-instructor-quote > blockquote {
        font-size: 25px;
        font-family: "Inter";
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.642);
        border-left: 4px solid #30b0a57d;
        padding-left: 20px;
    }

    .author-page-landing-image {
        width: 460px;
    }

    .author-page-landing {
        padding: 15vh calc((100% - 1000px) / 2);
    }

    .author-instructor-quote {
        width: 800px;
        padding: 15px 30px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        border-radius: 5px;
    }

    #author-quote-carousel-container {
        margin: 0 auto;
        width: 1000px;
    }

    .author-instructor-quote > blockquote {
        font-size: 25px;
        font-family: "Inter";
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.642);
        border-left: 4px solid #30b0a57d;
        padding-left: 20px;
    }

    /* -------- Creator Intro Page -------- */
    .creator-intro-section {
        background-color: #f0f2f5;
        padding: 20vh 7vw;
    }

    .creator-intro-hook {
        width: 700px;
    }

    .creator-intro-hook > h1 {
        display: inline-block;
        font-size: 30px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 33px;
        color: #000000;
    }

    .creator-intro-hook > h3 {
        font-size: 40px;
        font-family: "Inter";
        font-weight: 500;
        color: #000000;
        text-align: center;
        margin-top: -80px;
        margin-bottom: 0;
    }

    .creator-intro-hook > h6 {
        font-size: 18px;
        font-family: "Inter";
        font-weight: 300;
        font-style: italic;
        color: #000000;
    }

    .creator-intro-hook > p {
        font-size: 18px;
        font-family: "Inter";
        font-weight: 300;
        color: #000000;
    }

    .creator-intro-right-aligned {
        text-align: right;
    }

    .creator-intro-first {
        background-color: #f0f2f5;
        margin-bottom: 150px;
    }

    .creator-intro-second {
        background: #01161e;
        padding: 130px 100px;
    }

    .creator-intro-third {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .creator-intro-fourth {
        background-color: rgba(0, 0, 0, 0.075);
    }

    .creator-intro-fifth {
        background-color: #faf9f9;
        padding: 75px 115px;
    }

    .creator-benefits-text {
        color: white;
        font-size: 22px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 26px;
    }

    .creator-benefits-one {
        padding: 25px 25px;
        background-color: #273745;
    }

    .creator-benefits-two {
        padding: 25px 25px;
        background-color: #31467c;
        text-align: right;
    }

    .creator-benefits-three {
        padding: 25px 25px;
        background-color: #7b9ea8;
    }

    .creator-explanations-card {
        padding: 50px;
        background: #faf9f9;
        border-radius: 10px;
        width: 600px;
    }

    .creator-explanations-card > h3 {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 700;
        line-height: 26px;
    }

    .creator-explanations-card > p {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 300;
        line-height: 26px;
    }

    .creator-explanations-row-one {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-two {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-three {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 300px;
    }

    .creator-explanations-row-four {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 50px;
    }

    #creator-faq-card-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
        row-gap: 50px;
        margin-bottom: 100px;
    }

    .creator-faq-section > h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
    }

    .creator-faq-card {
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 575px;
    }

    .creator-faq-card > h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }

    .creator-faq-card > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
    }

    #creator-end-hook {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 25px;
    }

    .home-collection-view-btn {
        background: #509f83;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        transition: all 0.2s ease-in;
        border: 1px solid #f5f5f5;
    }

    .home-collection-view-btn:hover {
        background-color: white;
        border: 1px solid #509f83;
        color: #509f83;
    }

    .home-collection-one {
        background-color: #020b18;
        padding: 50px calc((100% - 1400px) / 2);
        transition: all 0.3s ease-in;
    }

    .home-collection-two {
        background-color: #2e4d7d;
        padding: 50px calc((100% - 1400px) / 2);
        transition: all 0.3s ease-in;
    }

    .home-collection-three {
        background-color: #ac2430;
        padding: 50px calc((100% - 1400px) / 2);
        transition: all 0.3s ease-in;
    }

    /* -------- END --------- */

    .creator-explanations-row-one {
        max-width: 1200px;
        margin: 200px auto;
    }

    .creator-explanations-row-two {
        max-width: 1200px;
        margin: 200px auto;
    }

    .creator-explanations-row-three {
        max-width: 1200px;
        margin: 200px auto;
    }

    .creator-explanations-row-four {
        max-width: 1200px;
        margin: 200px auto;
    }

    .creator-faq-section {
        margin: 0 auto;
        max-width: 1850px;
    }
}

// Screen Size: xl
@media screen and (min-width: 1680px) {
    #creator-panel,
    #premium-collection-mid-section,
    #purchased-content-page,
    #promoted-collection-mid-section,
    #premium-collection-mid-section,
    #report-issue-page,
    #scheduled-licenses-page {
        margin: 10px auto;
        max-width: 1440px;
    }
}

.upload-bulk-questions .upload-section {
    height: 175px;
}

.upload-bulk-questions .upload-label {
    margin-top: 10px;
}

.upload-bulk-questions .preview-structure {
    display: flex;
    flex-direction: row;
}

.upload-bulk-questions .preview-structure .preview-structure-left {
    border-right: 1px solid lightgray;
    width: 30vw;
    height: calc(100vh - 122px);
    overflow-y: auto;
}

.upload-bulk-questions .preview-structure .preview-structure-left .item .label {
    padding: 5px;
    border: 1px solid lightgrey;
    margin-top: 5px;
    background: #fbfbfb;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    transition: 0.3s;
}

.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label
    .icon-section {
    margin-left: auto;
}

.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label
    .icon-section
    .warning {
    color: orange;
}

.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label
    .icon-section
    .danger {
    color: red;
}

.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label.question {
    cursor: pointer;
}
.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label.question:hover,
.upload-bulk-questions
    .preview-structure
    .preview-structure-left
    .item
    .label.question.selected {
    background: #ebeaea;
}

.upload-bulk-questions .preview-structure .preview-structure-left .item.pl {
    margin-left: 20px;
}

.upload-bulk-questions .preview-structure .preview-structure-right {
    width: 37.5vw;
    height: calc(100vh - 122px);
    overflow-y: auto;
    padding: 10px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}

.ml-auto {
    margin-left: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.course-certificate-badge-container {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    background: rgb(54, 178, 167);
    background: -moz-linear-gradient(
        0deg,
        rgba(54, 178, 167, 1) 0%,
        rgba(255, 224, 125, 1) 100%
    );
    background: -webkit-linear-gradient(
        0deg,
        rgba(54, 178, 167, 1) 0%,
        rgba(255, 224, 125, 1) 100%
    );
    background: linear-gradient(
        0deg,
        rgba(54, 178, 167, 1) 0%,
        rgba(255, 224, 125, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36b2a7",endColorstr="#ffe07d",GradientType=1);
    border: thin solid #39c3b76e;
    border-top: 0;
}

.course-certificate-badge-container .course-certificate-badge {
    width: 50px;
    height: 50px;
    -webkit-filter: drop-shadow(0px 0px 12px #222);
    filter: drop-shadow(0px 0px 12px #222);
}

.course-previewer .course-previewer-video-previewer {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
}

.course-previewer .course-previewer-video-previewer > div {
    width: 100%;
}

.vimeo-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: calc(100% - 48px);
    background-color: black;
}

.vimeo-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.course-previewer-thumbnail,
.course-previewer-video-previewer {
    transition: opacity 300ms linear 100ms;
}

.category-list-container,
.sub-category-list-container {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    -moz-column-gap: 5px;
    -webkit-column-gap: 5px;
}

.sub-category-list-container {
    padding-top: 0;
}

.collection-category-btn,
.collection-sub-category-btn {
    background-color: rgb(141, 141, 141);
    border-radius: 5px;
    padding: 10px 13px;
    margin: 0px;
    font-size: 12px;
    font-family: "Inter";
    font-weight: 700;
    color: rgb(255, 255, 255);
    height: 36px;
    line-height: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 5px;
    -moz-column-gap: 5px;
    -webkit-column-gap: 5px;
}

.collection-category-btn.active {
    background-color: rgb(74, 106, 142);
    color: rgb(255, 255, 255);
}

.collection-category-btn:hover,
.collection-category-btn:focus {
    background-color: rgb(128, 128, 128);
    color: rgb(255, 255, 255);
    border-color: rgb(217, 217, 217);
}

.collection-category-btn.active:hover,
.collection-category-btn.active:focus {
    background-color: rgb(67, 95, 128);
    color: rgb(255, 255, 255);
    border-color: rgb(217, 217, 217);
}

.collection-sub-category-btn {
    background-color: rgb(241, 240, 240);
    color: gray;
}

.collection-sub-category-btn.active {
    background-color: rgb(223, 223, 223);
    color: gray;
}
.collection-sub-category-btn:hover,
.collection-sub-category-btn:focus {
    background-color: rgb(230, 230, 230);
    color: gray;
    border-color: rgb(217, 217, 217);
}

.collection-sub-category-btn.active:hover,
.collection-sub-category-btn.active:focus {
    background-color: rgb(212, 212, 212);
    color: gray;
}

.collection-details-bullets {
    display: flex;
    flex-direction: row;
    grid-column-gap: 16px;
    column-gap: 16px;
    margin-bottom: 12px;
}

.collection-details-bullets > span {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}

#promotion-tab-content {
    height: calc(100vh - 185px);
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    text-align: left;
    letter-spacing: 0.5px;
    position: relative;
    scroll-behavior: smooth;
    margin: 5px 0px;
}

#promotion-tab-content.quiz-question-creator-transcript {
    height: calc(50vh);
}

#promotion-tab-content::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.promotion-price {
    font-size: 20px;
}

.strike-price {
    text-decoration: none;
    position: relative;
}
.strike-price::before {
    top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
    background: red; /*this is the color of the line*/
    opacity: 0.7;
    content: "";
    width: 110%;
    position: absolute;
    height: 0.1em;
    border-radius: 0.1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
}
.strike-price.straight::before {
    transform: rotate(0deg);
    left: -1%;
    width: 102%;
}

.strike-price.small {
    font-size: 12px;
}

.promo-apply-label {
    background-color: #e9d171;
    color: #706539;
    border: thin solid #8b7d48;
    padding: 3px;
    border-radius: 2px;
    width: 150px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    margin-top: 6px;
}
.promo-collection-list-item {
    display: flex;
}
.promo-collection-list-item .detail-section {
    width: 100%;
}

.promo-collection-list-item .detail-section h4 {
    font-size: 17px;
}

.promo-collection-list-item .head-section {
    display: flex;
}
.promo-collection-list-item .image-section {
    margin-left: auto;
}

.promo-collection-list-item h4 {
    cursor: pointer;
}

.promo-off-label {
    position: relative;
    text-align: center;
    color: #fff;
    background-color: red;
}

.crete-new-course-button-wrap {
    width: 80%;
    height: 80%;
}

.crete-new-course-button-wrap .crete-new-course-button {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.crete-new-course-button-wrap .crete-new-course-button .label {
    font-weight: bold;
}

.crete-new-course-button-wrap .crete-new-course-button .icon {
    font-size: 24px;
}

.video-bank-count-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.video-bank-count-container .import-button {
    margin-left: auto;
}

.import-more-video-form {
    display: flex;
}

.import-more-video-form .ant-form-item {
    width: 100%;
    margin-bottom: 0;
}

.import-more-video-form .ant-form-item .ant-form-item-extra {
    padding: 4px 0px;
    line-height: 1.2;
    font-size: 12px;
}

.import-more-video-form input,
.import-more-video-form button {
    border-radius: 0;
}

.divider-button-panel {
    // margin: 0 !important;
}

.divider-button-panel::after,
.divider-button-panel::before {
    top: 0 !important;
}

.divider-button-panel .ant-divider-inner-text {
    display: flex;
    gap: 10px;
}

.video-bank {
    border: 1px solid #f0f0f0;
}

.video-bank-list,
.collection-creator-modules-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 10px 0px;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}

.video-bank-list {
    height: calc(100vh - 42vh);
}

.collection-creator-modules-list {
    padding: 0;
    // height: calc(100vh - 53vh);
}

.collection-creator-modules-list {
}

// .collection-creator-modules-list {
//     display: flex;
//     width: 100%;
//     height: 90%;
//     align-items: center;
//     flex-direction: column;
//     justify-content: flex-start;
// }

// .collection-creator-modules-list-scrollable {
//     display: flex;
//     overflow: auto;
//     align-items: center;
//     padding: 10px 15px 0;
//     flex-direction: column;
// }

.module-list-title {
    display: flex;
    flex-direction: row;
    // padding-bottom: 10px;
    // border-bottom: 1px solid #f0f0f0;
    justify-content: center;
    align-items: center;
    line-height: 1;
}

.module-list-title .dragging-section {
    // padding: 0 10px;
}

.module-list-title .seperator {
    border-left: 1px solid #f0f0f0;
    margin: 0 10px;
    height: 30px;
}

.module-list-title .editable-title-container {
    width: 100%;
}

.module-list-title .editable-title-container .ant-typography {
    margin-bottom: 0 !important;
}

// .module-list-title .action-panel {
//     display: flex;
//     flex-direction: row;
// }

.module-list-title .total-price-container {
    width: 260px;
}

.module-list-title .author-container {
    width: 250px;
}

.module-list-title .delete-container {
    width: 50px;
}

// .collection-creator-modules-list.drop-over .videos-in-module-list-container {
//     display: none;
// }

.module-list-title .display-section {
    display: flex;
    flex-direction: column;
}

.module-list-title .display-section .display-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    font-weight: bold;
    align-items: center;
}

.module-list-title .display-section .display-secondary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: gray;
    align-items: center;
}

.collection-creator-videos-in-module-list.no-video::after {
    width: 100%;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "Drag and drop videos to and from Video Bank";
}

// Custom scroll START

.custom-scrollbar-x {
    overflow: hidden;
    overflow-x: auto;
}

.custom-scrollbar-y {
    overflow: hidden;
    overflow-y: auto;
}

.custom-scrollbar-x:hover,
.custom-scrollbar-y:hover {
    // scrollbar firefox
    scrollbar-color: white;
    // scrollbar chrome/edge
}

.custom-scrollbar-x:hover::-webkit-scrollbar-track,
.custom-scrollbar-x:hover::-webkit-scrollbar-thumb,
.custom-scrollbar-y:hover::-webkit-scrollbar-track,
.custom-scrollbar-y:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.custom-scrollbar-x::-webkit-scrollbar-track,
.custom-scrollbar-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    visibility: hidden;
}

.custom-scrollbar-x::-webkit-scrollbar {
    height: 6px !important;
}
.custom-scrollbar-y::-webkit-scrollbar {
    width: 6px !important;
}

.custom-scrollbar-x::-webkit-scrollbar-thumb,
.custom-scrollbar-y::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    visibility: hidden;
}

// Custom scroll END

.triangle-top-right {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent #00000073 transparent transparent;
}

.delete-video-row-card {
    position: absolute;
    right: -40px;
    top: 5px;
    color: #ededed;
    cursor: pointer;
}

.no-list-style {
    list-style-type: none;
}

.collection-creator-modules-list
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    align-items: center;
}

.collection-creator-modules-list
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    padding-left: 12px;
}


.full-height-grid-list .ant-col {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.full-height-grid-list .ant-list-item {
  height: 100% !important;
}

// Collection Course Card Styles: Start
.mini-collection-card {
    background-color: white;
    width: 100%;
    height: 100%;
    flex: 1;
}

.mini-collection-card .mini-collection-card-image-bio {
    overflow: hidden;
}

.mini-collection-card .mini-collection-card-text {
    padding: 8px;
}

.mini-collection-card .mini-collection-card-text .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;
}

.mini-collection-card .mini-collection-card-text .price {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
}

.mini-collection-card .mini-collection-card-text .description {
    display: -webkit-box;
    max-width: 400px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mini-collection-card-image-bio .course-previewer {
    position: relative;
}
// Collection Course Card Styles: End



// Collection Course Card Styles: Start
.certificate-collection-card {
    background-color: white;
    width: 100%;
    height: 100%;
    flex: 1;
}

.certificate-collection-card .certificate-collection-card-image-bio {
    overflow: hidden;
}

.certificate-collection-card .certificate-collection-card-text {
    padding: 8px;
}

.certificate-collection-card .certificate-collection-card-text .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;
}

.certificate-collection-card .certificate-collection-card-text .price {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
}

.certificate-collection-card .certificate-collection-card-text .description {
    display: -webkit-box;
    max-width: 400px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.certificate-hover-card-details .certificate-collection-card-view-btn-section {
    width: 100%;
    display: flex;
    text-align: center;
}

.certificate-hover-card-details .certificate-collection-card-view-btn {
    width: 100%;
    color: #30b0a5;
    background-color: white;
    border: 2px solid #30b0a5;
    padding: 10px 15px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
}

.certificate-hover-card-details .certificate-collection-card-view-btn:hover {
    background-color: rgb(226, 226, 226);
}

.certificate-collection-card-image-bio .course-previewer {
    position: relative;
}
// Collection Course Card Styles: End

// Special Button
.special-btn {
    display: inline-block;
    background: linear-gradient(90deg, #30b0a5 21.17%, #4339b1 100%);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 700;
    color: white;
}
@primary-color: #0aa679;@badge-color: #722ed1;@layout-header-background: #f0f2f5;@btn-default-ghost-border: #E7E7E7;@btn-default-ghost-color: #000;@menu-dark-color: #000;